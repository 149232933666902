import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import rootStore from './stores/RootStore';
import ScrollToTop from './components/base/scroll/ScrollToTop';
import App from './App';
import ErrorBoundry from './components/base/error/ErrorBoundry';
import * as serviceWorker from './serviceWorker';
import 'loaders.css/loaders.css';
import './assets/css/style.css';

Sentry.init({
	dsn: "https://0f1590fa731d42c6b340d2ac80d80197@sentry.io/1445693",
	release: "fazaa-frontend@" + (process.env.REACT_APP_CURRENT_GIT_SHA ? process.env.REACT_APP_CURRENT_GIT_SHA : 'unversioned'),
	enabled: process.env.NODE_ENV === 'production',
	ignoreErrors: [
		'Network Error',
		'Request aborted',
		'timeout of 0ms exceeded',
		"Can\'t execute code from a freed script",
		"NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
		"NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
		"The operation is insecure.",
		"NotFoundError: The object can not be found here.",
		"Non-Error promise rejection captured",
		"Object Not Found Matching Id",
		"MobX injector: Store 'rootStore' is not available! Make sure it is provided by some Provider"
	]
});

configure({
	enforceActions: 'observed'
});

ReactDOM.render((
	<ErrorBoundry>
		<Provider rootStore={rootStore}>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<ScrollToTop>
					<App />
				</ScrollToTop>
			</BrowserRouter>
		</Provider>
	</ErrorBoundry>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
