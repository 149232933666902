import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { chunk } from 'lodash';
import Config from '../../config/Config';
import Qs from 'qs';
import Loader from 'react-loaders';
import FilterMultiSelect from '../base/filter/FilterMultiSelect';
import SearchType from '../../types/SearchType';
import { autorun } from 'mobx';
import Localized from '../base/i18n/Localized';
import SearchLink from '../base/link/SearchLink';

// TODO Remove withRouter as it is not needed here?
const Search = inject('rootStore')(withRouter(observer(
	class Search extends Component {

		constructor(props) {
			super(props);

			const { searchStore } = props.rootStore;

			searchStore.setLoading(true);
			searchStore.setError(false);
			searchStore.clearResults();

			this.handleTypesChange = this.handleTypesChange.bind(this);
			this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
			this.handleLocationsChange = this.handleLocationsChange.bind(this);
			this.handleDistanceChange = this.handleDistanceChange.bind(this);
			this.scrollListener = this.scrollListener.bind(this);
			this.triggerSearch = this.triggerSearch.bind(this);

			this.mobxAutorunDisposer = autorun(() => {
				const { pageTitlePrefix, translateMessage } = props.rootStore.commonStore;

				document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + translateMessage('page.search.title');
			});
		}

		componentDidMount() {
			// Check if language param is missing and redirect
			const { commonStore, userStore: { authenticated, isProfileComplete, isPasswordChangeNeeded }, searchStore } = this.props.rootStore;

			// Prevent redirect loop that can sometimes happen on search page
			if (authenticated && (!isProfileComplete || isPasswordChangeNeeded)) return;

			const { search } = this.props.location;

			searchStore.loadLocations();

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			if (!params.language) {
				params.language = commonStore.locale;

				const query = Qs.stringify(params, {
					arrayFormat: 'repeat'
				});

				this.props.history.push(`/search?${query}`);
			} else {
				this.triggerSearch();
			}

			// Add scroll event listener
			window.addEventListener('scroll', this.scrollListener);
		}

		componentDidUpdate(prevProps) {
			const { commonStore, userStore: { authenticated, isProfileComplete, isPasswordChangeNeeded }, searchStore } = this.props.rootStore;

			// Prevent redirect loop that can sometimes happen on search page
			if (authenticated && (!isProfileComplete || isPasswordChangeNeeded)) return;

			const { search } = this.props.location;

			searchStore.setMapSearchClosing(false);

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			if (!params.language) {
				params.language = commonStore.locale;

				const query = Qs.stringify(params, {
					arrayFormat: 'repeat'
				});

				this.props.history.push(`/search?${query}`);
			} else {
				if (prevProps.location.search !== this.props.location.search) {
					this.triggerSearch();
				}

				if (params.language !== commonStore.locale) {
					// Translate category names
					if (params.categories && params.categories.length > 0) {
						const translatedCategories = [];

						if (Array.isArray(params.categories)) {
							for (let categoryName of params.categories) {
								const category = searchStore.availableCategories.find(ac => ac.localData[params.language].name === categoryName);

								if (category) {
									translatedCategories.push(category.localData[commonStore.locale].name);
								}
							}
						} else {
							const category = searchStore.availableCategories.find(ac => ac.localData[params.language].name === params.categories);

							if (category) {
								translatedCategories.push(category.localData[commonStore.locale].name);
							}
						}

						params.categories = translatedCategories;
					}

					params.language = commonStore.locale;

					const query = Qs.stringify(params, {
						arrayFormat: 'repeat'
					});

					this.props.history.push(`/search?${query}`);
				}
			}
		}

		componentWillUnmount() {
			const { searchStore } = this.props.rootStore;

			searchStore.setMapSearchClosing(true);
			searchStore.setMapSearchOpen(false);

			// Remove scroll event listener
			window.removeEventListener('scroll', this.scrollListener)

			this.mobxAutorunDisposer();
		}

		handleTypesChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.types = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleCategoriesChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.categories = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleLocationsChange(values) {
			const { search } = this.props.location;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.cities = values;

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		handleDistanceChange(value) {
			const { search } = this.props.location;
			// const { userStore } = this.props.rootStore;

			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			params.distanceInKm = value;

			/*
			if (!!value) {
				params.latitude = userStore.position.latitude;
				params.longitude = userStore.position.longitude;
			} else {
				delete params.latitude;
				delete params.longitude;
			}
			*/

			const query = Qs.stringify(params, {
				arrayFormat: 'repeat'
			});

			this.props.history.push(`/search?${query}`);
		}

		scrollListener(e) {
			const { rootStore } = this.props;
			const { /*categoriesRef,*/ footerRef } = rootStore.commonStore;
			const { searchStore } = rootStore;
			const { loading } = searchStore;

			if (searchStore.lastScrollPosition > window.pageYOffset) {
				return;
			}

			if (/*categoriesRef == null ||*/ footerRef == null) {
				return;
			}

			if (window.pageYOffset + window.innerHeight <= document.body.scrollHeight /*- categoriesRef.scrollHeight*/ - footerRef.scrollHeight) {
				return;
			}

			if (searchStore.total === searchStore.results.length) {
				return;
			}

			if (!loading) {
				searchStore.lastScrollPosition = window.pageYOffset;

				this.triggerSearch(true);
			}
		}

		triggerSearch(nextPage = false) {
			const { search } = this.props.location;
			const { searchStore } = this.props.rootStore;
			const params = Qs.parse(search, {
				ignoreQueryPrefix: true
			});

			searchStore.doSearch(params, nextPage);
		}

		render() {
			const { commonStore, userStore, searchStore } = this.props.rootStore;
			const { locale } = commonStore;
			const types = SearchType.map(type => ({
				value: type.value,
				label: commonStore.translateMessage('page.search.label.' + type.value.toLowerCase())
			}));
			const categories = searchStore.availableCategories.map(category => ({
				value: category.localData[locale].name,
				label: category.localData[locale].name
			}));
			const locations = searchStore.locations.map(location => ({
				heading: location.heading,
				label: location.heading ? location.country.localData[locale].name : location.city.localData[locale].name,
				value: location.heading ? location.country.internalName : location.city.internalName
			}));
			const haveGeoLocation = !!userStore.position;
			const distances = [{
				value: undefined, label: commonStore.translateMessage('page.search.label.anyDistance')
			}, {
				value: 5, label: commonStore.translateMessage('page.search.label.5kmRadius')
			}, {
				value: 10, label: commonStore.translateMessage('page.search.label.10kmRadius')
			}, {
				value: 25, label: commonStore.translateMessage('page.search.label.25kmRadius')
			}, {
				value: 50, label: commonStore.translateMessage('page.search.label.50kmRadius')
			}];
			const { loading, previousResultCount } = searchStore;
			const results = searchStore.total > 0 ? chunk(searchStore.results, 4) : [];

			return (
				<section className="section section--gray">
					<h2 className="section__title section__title--center">
						<Localized code="page.search.title" />
					</h2>

					<div className="section__wrapper">
						<div className="search-page">
							<div className="search-page__filters">
								<div className="search-page__filter">
									<FilterMultiSelect items={types} selected={searchStore.types} onChange={this.handleTypesChange} placeholder={commonStore.translateMessage('page.search.label.allTypes')} />
								</div>

								<div className="search-page__filter">
									<FilterMultiSelect items={categories} selected={searchStore.categories} onChange={this.handleCategoriesChange} placeholder={commonStore.translateMessage('page.search.label.allCategories')} />
								</div>

								<div className="search-page__filter">
									<FilterMultiSelect items={locations} selected={searchStore.cities} onChange={this.handleLocationsChange} placeholder={commonStore.translateMessage('page.search.label.cities')} />
								</div>

								{haveGeoLocation ? (
								<div className="search-page__filter">
									<FilterMultiSelect single items={distances} selected={searchStore.distance} onChange={this.handleDistanceChange} placeholder={commonStore.translateMessage('page.search.label.anyDistance')} />
								</div>
								) : null}
							</div>

							{results.length > 0 ? (
								<div className="search-page__listing">
									{results.map((row, rowIndex) => (
										<TransitionGroup key={rowIndex} appear className="search-page__listing-row">
											{row.map((result, index) => {
												const delay = (rowIndex * 200) + (index * 50) - (previousResultCount * 50);

												return (
													<CSSTransition key={result.id} timeout={300 + delay} classNames="search-page__result">
														<div className="search-page__result" style={{transitionDelay: `${delay}ms`}}>
															<SearchLink className="search-page__result-link" result={result}>
																<div className="search-page__result-image-wrapper">
																	<img className="search-page__result-image" src={`${Config.UPLOADS_ENDPOINT}${result.imageUri}`} alt={result.title} />

																	<div className="search-page__result-type">{commonStore.translateMessage('page.search.label.' + result.type.toLowerCase())}</div>
																</div>

																<div className="search-page_result-meta">
																	{!!result.subTitle ? (
																		<h3 className="search-page__result-partner">{result.subTitle}</h3>
																	) : null}
																	
																	<p className="search-page__result-title">{result.title}</p>
																</div>
															</SearchLink>
														</div>
													</CSSTransition>
												)
											})}
										</TransitionGroup>
									))}
								</div>
							) : null}

							{results.length === 0 && !loading ? (
								<div className="search-page__message">
									<p className="search-page__message-text">
										<Localized code="page.search.text.noResults" />
									</p>
								</div>
							) : null}

							{loading ? (
								<div className="search-page__loading">
									<Loader type="line-scale-pulse-out-rapid" color="#002652" />
								</div>
							) : null}
						</div>
					</div>
				</section>
			);
		}

	}
)));

export default Search;
