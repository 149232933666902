import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

const ServiceLink = observer(class ServiceLink extends Component {

	render() {
		const { service, children, slugField, noBlank = false, ...rest } = this.props;

		if (parseInt(service.serviceType) === 2) {
			if (service.serviceLink.startsWith('http')) {
				return (
					<a href={service.serviceLink} target={noBlank ? '_self' : '_blank' } rel="noopener noreferrer" {...rest}>
						{children}
					</a>
				);
			} else {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={service.serviceLink} {...rest}>
						{children}
					</Link>
				);
			}
		} else {
			if (service.serviceSubType && service.serviceSubType === 'CARS') {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={`/services/view/long-term-car-lease/${service[slugField]}`} {...rest}>
						{children}
					</Link>
				);
			} else {
				return (
					<Link target={noBlank ? '_self' : '_blank' } to={`/services/view/${service[slugField]}`} {...rest}>
						{children}
					</Link>
				);
			}
		}
	}

});

ServiceLink.defaultProps = {
	slugField: 'serviceSlug'
};

export default ServiceLink;
