import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import Localized from '../../base/i18n/Localized';
import Swiper from '@eredessil/react-id-swiper';
import Config from '../../../config/Config';
import { autorun } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import Highlighter from 'react-highlight-words';
import ReactLoading from 'react-loading';

import imgLogo from '../../../assets/img/logo-fazaa.png';
// import imgLogoAnimated from '../../../assets/img/fazaa-and-tolerance-r1.gif';
import imgLogoWide from '../../../assets/img/logo-fazaa-wide.png';
import imgHeaderCartIcon from '../../../assets/img/header-icon-cart.png';
import imgHeaderCartIconBlue from '../../../assets/img/header-icon-cart-blue.png';
import imgHeaderUserIcon from '../../../assets/img/header-icon-user.png';
// import imgHeaderUserIconBlue from '../../../assets/img/header-icon-user-blue.png';
import imgHeaderMapIcon from '../../../assets/img/header-icon-map-2.png';
import imgHeaderMapIconBlue from '../../../assets/img/header-icon-map-2-blue.png';
import imgHeaderSearchIcon from '../../../assets/img/header-icon-search-white.png';
import imgHeaderSearchIconBlue from '../../../assets/img/header-icon-search-blue.png';
import imgIconHamad from '../../../assets/img/icon-hamad.png';
import imgSliderIconArrowLeft from '../../../assets/img/slider-icon-arrow-left.png';
import imgSliderIconArrowRight from '../../../assets/img/slider-icon-arrow-right.png';
import imgHomeIcon from '../../../assets/img/icon-home.png';

import imgHeaderUsersIcon from '../../../assets/img/header-icon-users.png';
import imgHeaderUsersIconBlue from '../../../assets/img/header-icon-users-blue.png';

moment.tz.setDefault('Asia/Dubai');

const Header = inject('rootStore')(withRouter(observer(
	class Header extends Component {

		constructor(props) {
			super(props);

			this.state = {
				hamadNewsImageHeight: 0,
				activeQuickResultIndex: 0,
				quickSearchUIVisible: false
			};

			this.queryFieldRef = React.createRef();

			this.handleResize = this.handleResize.bind(this);
			this.showMobileMenu = this.showMobileMenu.bind(this);
			this.hideMobileMenu = this.hideMobileMenu.bind(this);
			this.showMobileMenuMain = this.showMobileMenuMain.bind(this);
			this.hideMobileMenuMain = this.hideMobileMenuMain.bind(this);
			this.showLanguageDropdown = this.showLanguageDropdown.bind(this);
			this.hideLanguageDropdown = this.hideLanguageDropdown.bind(this);
			this.showSwitchDropdown = this.showSwitchDropdown.bind(this);
			this.hideSwitchDropdown = this.hideSwitchDropdown.bind(this);
			this.handleLocaleClick = this.handleLocaleClick.bind(this);
			this.handleQueryChange = this.handleQueryChange.bind(this);
			this.handleSearchClick = this.handleSearchClick.bind(this);
			this.handleTooltipOKClick = this.handleTooltipOKClick.bind(this);
			this.handleTooltipCheckoutClick = this.handleTooltipCheckoutClick.bind(this);
			this.handleSlideChange = this.handleSlideChange.bind(this);
			this.handleOpenMapSearch = this.handleOpenMapSearch.bind(this);
			this.doQuickSearch = debounce(this.doQuickSearch.bind(this), 500);

			this.goPrev = this.goPrev.bind(this);
			this.goNext = this.goNext.bind(this);

			this.getTimeOfDay = this.getTimeOfDay.bind(this);

			this.headerCartRef = undefined;
			this.headerMobileCartRef = undefined;
			this.headerHamadRef = undefined;
			this.swiperRef = React.createRef();

			this.mobxAutorunDisposer = autorun(() => {
				const { commonStore, hamadStore } = this.props.rootStore;
				const { previousLocale, locale} = commonStore;

				if (previousLocale !== locale) {
					hamadStore.setReload(true);

					setTimeout(() => {
						hamadStore.setReload(false);
					}, 100);
				}
			});
		}

		componentDidMount() {
			const { hamadStore } = this.props.rootStore;

			hamadStore.loadAllNews().then(() => {
				this.handleResize();
			});

			window.addEventListener('resize', this.handleResize);
		}

		componentDidUpdate(prevProps) {
			if (prevProps.location.pathname !== this.props.location.pathname) {
				this.handleResize();
			}
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleResize);
			window.removeEventListener('click', this.hideQuickSearchUI);
		}

		goPrev() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slideNext();
			} else {
				this.swiperRef.current.swiper.slidePrev();
			}
		}

		goNext() {
			if (document.documentElement.classList.contains('--rtl')) {
				this.swiperRef.current.swiper.slidePrev();
			} else {
				this.swiperRef.current.swiper.slideNext();
			}
		}

		handleResize() {
			const { cartStore } = this.props.rootStore;
			const { showTooltip } = cartStore;

			if (showTooltip) {
				cartStore.setShowTooltip(false);
			}

			if (this.headerHamadRef) {
				if (this.state.hamadNewsImageHeight !== this.headerHamadRef.offsetHeight) {
					this.setState({
						hamadNewsImageHeight: this.headerHamadRef.offsetHeight
					});
				}
			}
		}

		setHeaderMobileRef(element) {
			this.setHeaderMobileRef = element;
		}

		showMobileMenu() {
			const { commonStore } = this.props.rootStore;

			commonStore.setMobileMenuOpen(true);

			document.addEventListener('click', this.hideMobileMenu);
		}

		hideMobileMenu() {
			const { commonStore } = this.props.rootStore;

			commonStore.setMobileMenuOpen(false);

			document.removeEventListener('click', this.hideMobileMenu);
		}

		showMobileMenuMain() {
			const { commonStore } = this.props.rootStore;

			commonStore.setMobileMenuMainOpen(true);

			document.addEventListener('click', this.hideMobileMenuMain);
		}

		hideMobileMenuMain() {
			const { commonStore } = this.props.rootStore;

			commonStore.setMobileMenuMainOpen(false);

			document.removeEventListener('click', this.hideMobileMenuMain);
		}

		showLanguageDropdown() {
			const { commonStore } = this.props.rootStore;

			commonStore.setLanguageDropdownOpen(true);

			document.addEventListener('click', this.hideLanguageDropdown);
		}

		hideLanguageDropdown() {
			const { commonStore } = this.props.rootStore;

			commonStore.setLanguageDropdownOpen(false);

			document.removeEventListener('click', this.hideLanguageDropdown);
		}

		showSwitchDropdown() {
			const { commonStore } = this.props.rootStore;

			commonStore.setSwitchDropdownOpen(true);

			document.addEventListener('click', this.hideSwitchDropdown);
		}

		hideSwitchDropdown() {
			const { commonStore } = this.props.rootStore;

			commonStore.setSwitchDropdownOpen(false);

			document.removeEventListener('click', this.hideSwitchDropdown);
		}

		handleLocaleClick(locale) {
			const { commonStore } = this.props.rootStore;

			commonStore.setLocale(locale);
		}

		handleQueryChange(e) {
			const { searchStore, estoreStore } = this.props.rootStore;

			if (estoreStore.storeActive) {
				estoreStore.setQuery(e.target.value);
			} else {
				searchStore.setQuery(e.target.value);

				if (searchStore.query && searchStore.query.trim().length > 0) {
					this.doQuickSearch();
				}
			}
		}

		doQuickSearch() {
			const { searchStore } = this.props.rootStore;

			if (searchStore.query && searchStore.query.trim().length > 0) {
				searchStore.doQuickSearch({ q: searchStore.query });
			}

			this.setState({ activeQuickResultIndex: 0 });
		}

		resolveResultItemURL = result => {
			var resolvedUrl = null;

			if (result.type === 'SERVICE') {
				if (parseInt(result.serviceType) === 2) {
					if (result.serviceLink.startsWith('http')) {
						window.location = result.serviceLink;

						return;
					} else {
						resolvedUrl = result.serviceLink;
					}
				} else {
					if (result.serviceSubType && result.serviceSubType === 'CARS') {
						resolvedUrl = `/services/view/long-term-car-lease/${result.slug}`;
					} else {
						resolvedUrl = `/services/view/${result.slug}`;
					}
				}
			} else if (result.type === 'OFFER_EXTRA') {
				resolvedUrl = `/extra-offers/view/${result.slug}`;
			} else {
				resolvedUrl = `/${result.type.toLowerCase()}s/view/${result.slug}`;
			}

			if (resolvedUrl) {
				this.props.history.push(resolvedUrl);
			}
		};

		handleSearchClick(e) {
			e.preventDefault();

			const { commonStore: { locale }, searchStore: { query: searchQuery }, estoreStore: { storeActive, query: estoreQuery} } = this.props.rootStore;

			if (storeActive) {
				this.props.history.push(`?language=${locale}${estoreQuery ? `&q=${estoreQuery}` : ''}`);
			} else {
				this.props.history.push(`/search?language=${locale}${searchQuery ? `&q=${searchQuery}` : ''}`);
			}
		}

		handleTooltipOKClick(e) {
			e.preventDefault();

			this.props.rootStore.cartStore.setShowTooltip(false);
		}

		handleTooltipCheckoutClick(e) {
			e.preventDefault();

			this.props.rootStore.cartStore.setShowTooltip(false);

			this.props.history.push('/cart');
		}

		handleSlideChange() {
			const { realIndex } = this.swiperRef.current.swiper;
			const { hamadStore } = this.props.rootStore;

			hamadStore.setCurrentSlideIndex(realIndex);
		}

		handleOpenMapSearch() {
			const { searchStore } = this.props.rootStore;

			searchStore.setMapSearchOpen(true);
		}

		getTimeOfDay() {
			const hour = parseInt(moment().format('H'));

			if (hour >= 12 && hour < 18) {
				return 'afternoon';
			}

			if (hour >= 18 && hour < 24) {
				return 'evening'
			}

			return 'morning';
		}

		handleSwitchClick = membershipNumber => {
			const { userStore } = this.props.rootStore;

			userStore.switchToLinkedMemberContext(membershipNumber).then(async () => {
				if (await userStore.completeUAEPassLogin()) {
					window.location.reload();
				}
			});
		};

		quickResultNavigate = () => {
			const { commonStore, searchStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { query: searchQuery, quickResults } = searchStore;

			if (this.state.activeQuickResultIndex !== 0) {
				const selectedResult = quickResults[this.state.activeQuickResultIndex - 1];

				// Resolve URL
				this.resolveResultItemURL(selectedResult);

				searchStore.setQuery('');
			} else {
				this.props.history.push(`/search?language=${locale}${searchQuery ? `&q=${searchQuery}` : ''}`);
			}

			this.setState({ quickSearchUIVisible: false, });

			if (this.queryFieldRef.current) {
				this.queryFieldRef.current.blur();
			}

			searchStore.clearQuickResults();
		};

		handleQuickResultClick = e => {
			this.quickResultNavigate();
		};

		handleQueryKeyDown = e => {
			const { commonStore, searchStore, estoreStore } = this.props.rootStore;
			const { locale } = commonStore;
			const { storeActive, query: estoreQuery } = estoreStore;

			switch (e.keyCode) {
				case 13:
					e.preventDefault();

					if (storeActive) {
						this.props.history.push(`?language=${locale}${estoreQuery ? `&q=${estoreQuery}` : ''}`);
					} else {
						this.quickResultNavigate();
					}
				break;

				case 27: // Escape
					searchStore.clearQuickResults();

					this.setState({ 
						quickSearchUIVisible: false,
						activeQuickResultIndex: 0,
					});

					if (this.queryFieldRef.current) {
						this.queryFieldRef.current.blur();
					}
				break;

				case 38: // Up
					this.setState(state => {
						if (state.activeQuickResultIndex > 0) {
							return { activeQuickResultIndex: state.activeQuickResultIndex - 1 }
						} else {
							return { activeQuickResultIndex: searchStore.quickResults.length }
						}
					});
				break;

				case 40: // Down
					this.setState(state => {
						if (state.activeQuickResultIndex < searchStore.quickResults.length ) {
							return { activeQuickResultIndex: state.activeQuickResultIndex + 1 }
						} else {
							return { activeQuickResultIndex: 0 }
						}
					});
				break;
			}
		};

		hideQuickSearchUI = e => {
			if (e.target.classList.contains('hamad__input') || 
				e.target.classList.contains('quick-search__item') || 
				e.target.closest('.quick-search__item')) {
				return;
			}

			const { searchStore } = this.props.rootStore;

			searchStore.clearQuickResults();

			this.setState({ 
				quickSearchUIVisible: false,
				activeQuickResultIndex: 0,
			});

			if (this.queryFieldRef.current) {
				this.queryFieldRef.current.blur();
			}
		};

		handleQueryFocus = e => {
			this.doQuickSearch();

			this.setState({ 
				quickSearchUIVisible: true,
			});

			window.addEventListener('click', this.hideQuickSearchUI);
		};

		// TODO Adjust search placeholder for estore search
		render() {
			const { commonStore, userStore, searchStore, cartStore, hamadStore, estoreStore } = this.props.rootStore;
			const { locale, languages, mobileMenuOpen, mobileMenuMainOpen, languageDropdownOpen, switchDropdownOpen, translateMessage } = commonStore;
			const { authenticated, theme, me, position } = userStore;
			const { reload, currentSlideIndex, news } = hamadStore;
			const showHamadChat = this.props.showHamadChat;
			const haveGeoLocation = !!position;
			const { query, nearMeTotal, quickResults, quickSearchLoading } = searchStore;
			const timeOfDay = this.getTimeOfDay();
			const { count, showTooltip } = cartStore;
			//const entertainmentCategory = searchStore.availableCategories.find(ac => ac.categoryInternalName === 'Entertainment');
			// const automotiveCategory = searchStore.availableCategories.find(ac => ac.categoryInternalName === 'Automotive');
			// const hotelResortsCategory = searchStore.availableCategories.find(ac => ac.categoryInternalName === 'Hotel & Resorts');
			const { storeActive, query: estoreQuery } = estoreStore;
			const { hamadNewsImageHeight } = this.state;

			const swiperParams = {
				slidesPerView: 1,
				resistanceRatio: 0,
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				autoplay: {
					delay: 5000
				},
				on: {
					init: () => {
						hamadStore.setCurrentSlideIndex(0);
					},
					slideChange: this.handleSlideChange
				},
				preloadImages: true,
				updateOnImagesReady: true
			};

			const tooltipStyle = {};
			const tooltipArrowStyle = {};

			if (showTooltip) { 
				const documentWidth = document.documentElement.clientWidth;

				if (this.headerMobileCartRef && window.getComputedStyle(this.headerMobileCartRef.parentElement).display !== 'none') {
					tooltipStyle.top = (this.headerMobileCartRef.getBoundingClientRect().top + window.scrollY + this.headerMobileCartRef.offsetHeight + 20) + 'px';

					if (documentWidth <= 650) {
						tooltipStyle.left = '20px';
						tooltipStyle.right = '20px';

						if (document.documentElement.dir === 'rtl') {
							tooltipArrowStyle.left = (this.headerMobileCartRef.getBoundingClientRect().left + (this.headerMobileCartRef.offsetWidth / 2) - 20 - 10) + 'px';
						} else {
							tooltipArrowStyle.right = (documentWidth - this.headerMobileCartRef.getBoundingClientRect().right + (this.headerMobileCartRef.offsetWidth / 2) - 20 - 10) + 'px';
						}
					} else {
						if (document.documentElement.dir === 'rtl') {
							tooltipStyle.left = (this.headerMobileCartRef.getBoundingClientRect().left + (this.headerMobileCartRef.offsetWidth / 2) - 30) + 'px';
							tooltipArrowStyle.left = '20px';
							
						} else {
							tooltipStyle.right = (documentWidth - this.headerMobileCartRef.getBoundingClientRect().right + (this.headerMobileCartRef.offsetWidth / 2) - 30) + 'px';
							tooltipArrowStyle.right = '20px';
						}
					}
				}

				if (this.headerCartRef && window.getComputedStyle(this.headerCartRef.parentElement).display !== 'none') {
					tooltipStyle.top = (this.headerCartRef.getBoundingClientRect().top + window.scrollY + this.headerCartRef.offsetHeight + 20) + 'px';

					if (document.documentElement.dir === 'rtl') {
						tooltipStyle.left = (this.headerCartRef.getBoundingClientRect().left + (this.headerCartRef.offsetWidth / 2) - 30) + 'px';
						tooltipArrowStyle.left = '20px';
					} else {
						tooltipStyle.right = (documentWidth - this.headerCartRef.getBoundingClientRect().right + (this.headerCartRef.offsetWidth / 2) - 30) + 'px';
						tooltipArrowStyle.right = '20px';
					}
				}
			}

			const hideNews = showHamadChat && news && news.length > 0 && news[currentSlideIndex].hideNews;

			return (
				<header className={`header header--${theme}`}>
					<div className="header__top">
						<div className="header__heading">
							<Link className="logo" to="/">
								<img className="logo__image" src={imgLogo} alt={translateMessage('generic.text.fazaa')} title={translateMessage('generic.text.fazaa')} />
								{false ? (
									<Fragment>
										<img className="logo__image logo__image--wide" src={imgLogoWide} alt={translateMessage('generic.text.fazaa')} title={translateMessage('generic.text.fazaa')} />
										<img className="logo__image logo__image--narrow" src={imgLogo} alt={translateMessage('generic.text.fazaa')} title={translateMessage('generic.text.fazaa')} />
									</Fragment>
								) : null}
							</Link>

							<div className="header__mobile">
								<Link className="header__mobile-search" to={`/search?language=${locale}`}>
									<img className="header__mobile-search-image" src={imgHeaderSearchIcon} alt="Search" />
								</Link>

								<button className="menu-toggle-map-search" onClick={this.handleOpenMapSearch}>
									<img className="menu-toggle-map-search__image" src={imgHeaderMapIcon} alt="Map search" />
								</button>

								<Link innerRef={el => this.headerMobileCartRef = el} className="header__mobile-cart" to="/cart">
									<img className="header__mobile-cart-image" src={imgHeaderCartIcon} alt="Cart" />
									<span className="header__mobile-cart-count">{count}</span>
								</Link>

								<button className="menu-toggle-user" onClick={this.showMobileMenu}>
									<img className="menu-toggle-user__image" src={imgHeaderUserIcon} alt="User menu" />
								</button>

								<button className="hamburger" onClick={this.showMobileMenuMain}>
									<span className="hamburger__line"></span>
									<span className="hamburger__line"></span>
									<span className="hamburger__line"></span>
								</button>
							</div>

							<div className={`header__menus ${mobileMenuOpen ? 'header__menus--open' : ''}`}>
								<ul className="user-menu">
									{!!authenticated ? (
									<Fragment>
										{me && me.uaePassList && me.uaePassList.length > 1 && (
											<li className={`user-menu__item user-menu__item--dropdown ${switchDropdownOpen ? 'user-menu__item--dropdown-open' : ''}`} onClick={this.showSwitchDropdown}>
												<span className="user-menu__dropdown">
													<img className="user-menu__image user-menu__image--white" src={imgHeaderUsersIcon} alt="Switch account" />
													<img className="user-menu__image user-menu__image--blue" src={imgHeaderUsersIconBlue} alt="Switch account" />
												</span>
												<ul className="user-menu__dropdown-items">
													{me.uaePassList && me.uaePassList.map(account => (
													<li key={account.membershipNumber} className="user-menu__dropdown-item" onClick={() => this.handleSwitchClick(account.id)}>
														{account.membershipNumber}
													</li>
													))}
												</ul>
											</li>
										)}
										<li className="user-menu__item">
											<Link className="user-menu__link" to="/account">
												<Localized code="header.userMenu.link.account" />
											</Link>
										</li>
										<li className="user-menu__item">
											<Link className="user-menu__link" to="/account/signout">
												<Localized code="header.userMenu.link.signOut" />
											</Link>
										</li>
									</Fragment>
									) : (
									<li className="user-menu__item">
										<Link className="user-menu__link" to="/account/signin">
											<Localized code="header.userMenu.link.signIn" />
										</Link>
									</li>
									)}
									<li className="user-menu__item user-menu__item--search user-menu__item--hide-mobile">
										<Link className="user-menu__link" to={`/search?language=${locale}`}>
											<img className="user-menu__image user-menu__image--white" src={imgHeaderSearchIcon} alt="Search" />
											<img className="user-menu__image user-menu__image--blue" src={imgHeaderSearchIconBlue} alt="Search" />
										</Link>
									</li>
									<li className="user-menu__item user-menu__item--hide-mobile">
										<button className="user-menu__button" onClick={this.handleOpenMapSearch}>
											<img className="user-menu__image user-menu__image--white" src={imgHeaderMapIcon} alt="Map search" />
											<img className="user-menu__image user-menu__image--blue" src={imgHeaderMapIconBlue} alt="Map search" />
											<span className="user-menu__button-text">
												<Localized code="header.userMenu.button.mapSearch" />
											</span>
										</button>
									</li>
									<li className="user-menu__item user-menu__item--cart">
										<Link innerRef={el => this.headerCartRef = el} className="user-menu__link" to="/cart">
											<img className="user-menu__image user-menu__image--white" src={imgHeaderCartIcon} alt="Cart" />
											<img className="user-menu__image user-menu__image--blue" src={imgHeaderCartIconBlue} alt="Cart" />
											<span className="user-menu__cart">{count}</span>
										</Link>
									</li>
									{false && languages.length > 0 ? (
									<li className={`user-menu__item user-menu__item--dropdown ${languageDropdownOpen ? 'user-menu__item--dropdown-open' : ''}`} onClick={this.showLanguageDropdown}>
										<span className="user-menu__dropdown">{locale.toUpperCase()}</span>
										<ul className="user-menu__dropdown-items">
											{languages.map(language => (
											<li dir={language.languageCode === 'ar' ? 'rtl' : 'ltr'} key={language.languageCode} className="user-menu__dropdown-item" onClick={() => this.handleLocaleClick(language.languageCode)}>
												{language.languageCode === 'ar'? 'العربية' : language.languageName} ({language.languageCode === 'ar' ? 'ع' : language.languageCode.toUpperCase()})
											</li>
											))}
										</ul>
									</li>
									) : null}
									<li className="user-menu__item">
										{locale === 'ar' ? (
											<a href="/" className="user-menu__link" onClick={e => { e.preventDefault(); this.handleLocaleClick('en')}}>English</a>
										) : (
											<a href="/" className="user-menu__link" onClick={e => { e.preventDefault(); this.handleLocaleClick('ar')}}>العربية</a>
										)}
									</li>
								</ul>

								<ul className="member-menu">
									{!authenticated ? (
										<li className="member-menu__item">
											<Link className="member-menu__link" to="/account/signup">
												<Localized code="header.memberMenu.link.signUp" />
											</Link>
										</li>
									) : (
										me && me.membershipLevel.levelName !== 'Platinum' ? (
											<li className="member-menu__item">
												<Link to="/account/upgrade" className="member-menu__link">
													<Localized code="header.memberMenu.link.upgrade" />
												</Link>
											</li>
										) : null
									)}
									<li className="member-menu__item">
										{authenticated ? (
											<Link className="member-menu__link" to="/account/signup/partner">
												<Localized code="header.memberMenu.link.suggestPartner" />
											</Link>
										) : (
											<Link className="member-menu__link" to="/page/become-fazaa-partner">
												<Localized code="header.memberMenu.link.becomePartner" />
											</Link>
										)}
									</li>
								</ul>
							</div>

							{showTooltip ? (
								<div className="checkout-tooltip" style={tooltipStyle}>
									<div className="checkout-tooltip__arrow" style={tooltipArrowStyle} />

									<p className="checkout-tooltip__text">
										<Localized code="header.cart.text.addedToCart" />
									</p>

									<div className="checkout-tooltip__buttons">
										<button className="button button--small button--blue" onClick={this.handleTooltipOKClick}>
											<Localized code="header.cart.button.continueShopping" />
										</button>

										<button className="button button--small button--gold" onClick={this.handleTooltipCheckoutClick}>
											<Localized code="header.cart.button.checkout" />
										</button>
									</div>
								</div>
							) : null}
						</div>

						<div className={`header__menu ${mobileMenuMainOpen ? 'header__menu--open' : ''}`}>
							<div className="main-menu">
								<ul className="main-menu__list">
									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/">
											<img className="main-menu__icon" src={imgHomeIcon} alt="Home" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/page/fazaa">
											<Localized code="header.menuMain.link.aboutFazaa" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/page/benefits">
											<Localized code="header.menuMain.link.benefits" />
										</NavLink>
									</li>

									{!Config.HIDE_DYNAMIC_OFFERS && (
										<li className="main-menu__item">
											<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to={`/search?language=${locale}&types=OFFER_EXTRA`} isActive={(match, location) => location.search.includes('types=OFFER_EXTRA') && location.search.match(/types=/g).length === 1}>
												<Localized code="header.menuMain.link.extra" />
											</NavLink>
										</li>
									)}

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/long-term-car-lease">
											<Localized code="header.menuMain.link.leaseToOwn" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/fazaa-used-cars">
											<Localized code="header.menuMain.link.usedCars" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/fazaa-daily-rent">
											<Localized code="header.menuMain.link.dailyRental" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/fazaa-amakin-special-offers">
											<Localized code="header.menuMain.link.fazaaAmakin" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/car-insurance">
											<Localized code="header.menuMain.link.insurance" />
										</NavLink>
									</li>

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/services/view/al-qard-al-hassan">
											<Localized code="header.menuMain.link.alQardAlHassan"/>
										</NavLink>
									</li>

									{false ? (
										<li className="main-menu__item">
											<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/page/topups">
												<Localized code="header.menuMain.link.howToTopUp" />
											</NavLink>
										</li>
									) : null}

									<li className="main-menu__item">
										<NavLink exact className="main-menu__link" activeClassName="main-menu__link--active" to="/page/faq">
											<Localized code="header.menuMain.link.faq" />
										</NavLink>
									</li>
								</ul>
							</div>
						</div>

						<div ref={el => this.headerHamadRef = el} className={"header__hamad" + (news.length === 0 || !showHamadChat ? " header__hamad--no-news" : "")}>
							{showHamadChat && news.length > 0 && !reload ? (
								<div className="hamad-news">
									<Swiper {...swiperParams} ref={this.swiperRef}>
										{news.map(n => (
											<div key={n.id} className="hamad-news__slide">
												{n.localData[locale].newsUrl && n.localData[locale].newsUrl.startsWith('http') ? (
													<a className="hamad-news__link" href={n.localData[locale].newsUrl} target="_blank">
														<img className="hamad-news__image" src={`${Config.UPLOADS_ENDPOINT}${n.localData[locale].imageUri}`} alt={n.localData[locale].shortNews} />
														<img className="hamad-news__image hamad-news__image--mobile" src={`${Config.UPLOADS_ENDPOINT}${n.localData[locale].mobileUri ? n.localData[locale].mobileUri : n.localData[locale].imageUri}`} alt={n.localData[locale].shortNews} />
													</a>
												) : ( 
													<Link className="hamad-news__link" to={n.localData[locale].newsUrl}>
														<img className="hamad-news__image" src={`${Config.UPLOADS_ENDPOINT}${n.localData[locale].imageUri}`} alt={n.localData[locale].shortNews} />
														<img className="hamad-news__image hamad-news__image--mobile" src={`${Config.UPLOADS_ENDPOINT}${n.localData[locale].mobileUri ? n.localData[locale].mobileUri : n.localData[locale].imageUri}`} alt={n.localData[locale].shortNews} />
													</Link>
												)}
											</div>
										))}
									</Swiper>

									<div className="hamad-news__arrow hamad-news__arrow--left" onClick={this.goPrev}>
										<img className="hamad-news__arrow-image" src={imgSliderIconArrowLeft} alt="Slide left" />
									</div>

									<div className="hamad-news__arrow hamad-news__arrow--right" onClick={this.goNext}>
										<img className="hamad-news__arrow-image" src={imgSliderIconArrowRight} alt="Slide right" />
									</div>
								</div>
							) : null}

							<div className={"hamad" + (!showHamadChat ? " hamad--nochat" : "") + (hideNews ? " hamad--hide" : "")}>
								{showHamadChat ? (
									<div className="hamad__chat">
										<div className="hamad__icon-wrap">
											<div className="hamad__icon">
												<img className="hamad__image" src={imgIconHamad} alt="Hamad" />
											</div>
										</div>

										<div className="hamad__bubble">
											<p className="hamad__text">
												{news && news.length > currentSlideIndex ? (
													<Fragment>
														<Link className="hamad__text-link" to={news[currentSlideIndex].localData[locale].newsUrl}>
															{news[currentSlideIndex].localData[locale].shortNews}
														</Link>
														{false ? (
															<span className="hamad__text-desc">PLACEHOLDER</span>
														) : null}
													</Fragment>
												) : (
													<Localized code="hamad.greeting" timeOfDay={timeOfDay} firstName={!!me ? (locale === 'ar' ? me.fullNameAr : me.fullNameEn) : translateMessage('hamad.guest')} />
												)}
											</p>

											{haveGeoLocation && nearMeTotal > 0 ? (
												<Link className="hamad__link" to={`/search?language=${locale}&latitude=${position.latitude}&longitude=${position.longitude}&distanceInKm=5`}>
													<Localized code="hamad.nearme.link" count={nearMeTotal} />
												</Link>
											) : null}
										</div>
									</div>
								) : null}

								{!showHamadChat ? (
									<div className="hamad__search">
										<div className="hamad__search-wrapper">
											<input ref={this.queryFieldRef} className="hamad__input" type="text" onChange={this.handleQueryChange} onKeyDown={this.handleQueryKeyDown} onFocus={this.handleQueryFocus} placeholder={storeActive ? translateMessage('header.estore.search.placeholder') : translateMessage('header.search.placeholder')} value={storeActive ? estoreQuery : query} />
											{quickSearchLoading && (
												<ReactLoading className="hamad__loading" type="spin" color="#999" height="20px" width="20px" />
											)}
											<button className="hamad__search-button" onClick={this.handleSearchClick} />
										</div>

										{query && this.state.quickSearchUIVisible && (
											<div className="quick-search hamad__quick-search">
												<ul className="quick-search__list">
													<li 
														className={`quick-search__item quick-search__item--search-for${this.state.activeQuickResultIndex === 0 ? ' quick-search__item--selected' : ''}`} 
														onMouseOver={() => this.setState({ activeQuickResultIndex: 0 })}
														onClick={this.handleQuickResultClick}
													>
														<div className="quick-search__text">Search for '<span className="quick-search__highlight">{query}</span>' ...</div>
														<FontAwesomeIcon className="quick-search__icon" icon={faChevronRight} />
													</li>
													{quickResults.map((qr, i) => (
														<li 
															key={qr.id}
															className={`quick-search__item${this.state.activeQuickResultIndex === i + 1 ? ' quick-search__item--selected' : ''}`}
															onMouseOver={() => this.setState({ activeQuickResultIndex: i + 1 })}
															onClick={this.handleQuickResultClick}
														>
															<div className="quick-search__tag">{qr.type}</div>
															{qr.subTitle && (
																<div className="quick-search__text">
																	<Highlighter
																		highlightClassName="quick-search__highlight"
																		searchWords={[ query ]}
																		textToHighlight={qr.subTitle}
																	/>
																</div>
															)}
															{qr.title && (
																<div className="quick-search__text quick-search__text--additional">
																<Highlighter
																		highlightClassName="quick-search__highlight"
																		searchWords={[ query ]}
																		textToHighlight={qr.title}
																	/>
																</div>
															)}
														</li>
													))}
												</ul>
											</div>
										)}
									</div>
								): null}
							</div>
						</div>
					</div>
				</header>
			);
		}

	}
)));

Header.propTypes = {
	showHamadChat: PropTypes.bool
};

Header.defaultProps = {
	showHamadChat: true
};

export default Header;
