import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import ServiceLink from './ServiceLink';

const SearchLink = observer(class SearchLink extends Component {

	render() {
		const { result, children, ...rest } = this.props;

		if (result.type === 'SERVICE') {
			return (
				<ServiceLink service={result} slugField="slug" {...rest}>
					{children}
				</ServiceLink>
			);
		} else if (result.type === 'OFFER_EXTRA') {
			return (
				<Link target="_blank" to={`/extra-offers/view/${result.slug}`} {...rest}>
					{children}
				</Link>
			);
		} else {
			return (
				<Link target="_blank" to={`/${result.type.toLowerCase()}s/view/${result.slug}`} {...rest}>
					{children}
				</Link>
			);
		}
	}

});

export default SearchLink;
