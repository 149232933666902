import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import CategoriesStrip from '../base/categories/CategoriesStrip';
import Swiper from '@eredessil/react-id-swiper';
import HTTP404 from '../base/error/HTTP404';
import Config from '../../config/Config';
import Localized from '../base/i18n/Localized';
import FileUpload from '../base/form/FileUpload';
import ProgressBar from '../base/form/ProgressBar';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import imgSliderIconArrowLeft from '../../assets/img/slider-icon-arrow-left.png'
import imgSliderIconArrowRight from '../../assets/img/slider-icon-arrow-right.png'

const Car = inject('rootStore')(withRouter(observer(
	class Car extends Component {

		constructor(props) {
			super(props);

			this.state = {
				car: null
			};

			this.swiperRef = React.createRef();
			this.leaseToOwnRef = React.createRef();

			this.handleFileChange = this.handleFileChange.bind(this);
			this.handleSignIn = this.handleSignIn.bind(this);
			this.handleSignUp = this.handleSignUp.bind(this);
			this.handleLeaseToOwnSubmitClick = this.handleLeaseToOwnSubmitClick.bind(this);

			this.scrollToTop = this.scrollToTop.bind(this);
		}

		componentDidMount() {
			const { commonStore, carStore, userStore } = this.props.rootStore;
			const { locale, pageTitlePrefix } = commonStore;
			const { carSlug } = this.props.match.params;

			carStore.loadCar(carSlug).then(car => {
				this.setState({ car });

				if (car) {
					document.title = (pageTitlePrefix ? pageTitlePrefix + ' - ' : '') + car.localData[locale].name;
				} else {
					document.title = pageTitlePrefix;
				}
			});

			userStore.clearUploads();
		}

		async handleFileChange(event, callback = null) {
			const { userStore } = this.props.rootStore;
			const target = event.target;
			const file = event.target.files[0];
			const name = target.name;
			const originalFileName = file.name;

			if (!!file) {
				await userStore.addUpload(name, file, originalFileName);

				if (callback !== null) {
					callback();
				}
			}
		}

		handleSignIn() {
			const { userStore } = this.props.rootStore;

			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signin');
		}

		handleSignUp() {
			const { userStore } = this.props.rootStore;
			
			userStore.setRedirectTo(this.props.location.pathname);

			this.props.history.push('/account/signup');
		}

		handleLeaseToOwnSubmitClick(e) {
			e.preventDefault();

			const { userStore } = this.props.rootStore;
			const { car } = this.state;

			userStore.clearErrors();
			userStore.submitLeaseToOwn(car.carInternalName).then(() => {
				this.scrollToTop();
			});
		}

		scrollToTop() {
			if (this.leaseToOwnRef.current) {
				requestAnimationFrame(() => {
					this.leaseToOwnRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				});
			}
		}

		isMemberEligible = () => {
			const { userStore } = this.props.rootStore;
			const { me: { membershipLevel, companyCode } = {} } = userStore;

			// Check membership level
			if (membershipLevel && (membershipLevel.id === 1 || membershipLevel.id === 2)) {
				return true;
			}

			// Check company
			// Make the following companies eligible even if membership level is not eligible
			// Nafis, Front Line, HEMAM, Hemam Intellectual
			if (companyCode) {
				if (['CC892', 'CC556', 'CC509', 'CC510'].includes(companyCode.companyCode)) {
					return true;
				}
			}

			return false;
		};

		render() {
			const { commonStore, carStore, userStore } = this.props.rootStore;
			const { locale, translateMessage } = commonStore;
			const { loading } = carStore;
			const { loading : userLoading, success, error, authenticated, me: { membershipLevel, companyCode } = {}, uploads } = userStore;
			const { car } = this.state;
			const swiperParams = {
				slidesPerView: 1,
				navigation: {
					prevEl: '.service-page__gallery-left',
					nextEl: '.service-page__gallery-right'
				},
				renderPrevButton: () => (
					<div className="service-page__gallery-left">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowLeft} alt="Slide left" />
					</div>
				),
  				renderNextButton: () => (
					<div className="service-page__gallery-right">
						<img className="service-page__gallery-arrow" src={imgSliderIconArrowRight} alt="Slide right" />
					</div>
				)
			};
			const submitDisabled = !uploads.emiratesId || !uploads.emiratesId.progress === 100 || !uploads.emiratesIdBack || !uploads.emiratesIdBack.progress === 100 || !uploads.drivingLicense || !uploads.drivingLicense.progress === 100 || !uploads.drivingLicenseBack || !uploads.drivingLicenseBack.progress === 100 || !uploads.salaryCertificate || !uploads.salaryCertificate.progress === 100 || !uploads.bankStatement || !uploads.bankStatement.progress === 100;
			const noUpgrade = !membershipLevel || membershipLevel.levelName === 'Platinum' || !!!companyCode.companyAllowSignup || companyCode.companyLevelId >= membershipLevel.id;

			return (
				!loading ? (
					car ? (
						<Fragment>
							<section className="section section--gray">
								<div className="section__wrapper">
									<div className="service-page">
										<div className="service-page__gallery">
											{car.images.length > 0 ? (
												<Swiper {...swiperParams} ref={this.swiperRef} rebuildOnUpdate>
													{car.images.map(image => (
														<div key={image.id} className="service-page__slide">
															<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${image.imageUri}`} alt="" />
														</div>
													))}
												</Swiper>
											) : (
												<img className="service-page__image" src={`${Config.UPLOADS_ENDPOINT}${car.carImageUri}`} alt={car.localData[locale].name} />
											)}
										</div>

										<div className="service-page__description">
											<CategoriesStrip categories={car.categories} />

											<h2 className="service-page__title">{car.localData[locale].name}</h2>

											<p className="section__text">
												<Localized code="page.leaseToOwn.text.availability" />: <Localized code={`page.leaseToOwn.text.${car.carStatus}`} />
											</p>

											<div className="cms-generated" dangerouslySetInnerHTML={{__html: car.localData[locale].description}} />
										</div>
									</div>

									<div ref={this.leaseToOwnRef} className="lease-to-own">
										{authenticated ? (
											this.isMemberEligible() ? (
												<div className="form lease-to-own__form">
													{!!success ? (
														<div className="form__section">
															<h4 className="form__title form__title--center">
																<Localized code="page.leaseToOwn.subtitle.success" />
															</h4>

															<p className="form__text form__text--center">
																<Localized code="page.leaseToOwn.text.success" />
															</p>
														</div>
													) : (
														<div className="form__section">
															<h4 className="form__title form__title--center">
																<Localized code="page.leaseToOwn.subtitle.submitFiles" />
															</h4>

															<p className="form__text form__text--center">
																<Localized code="page.leaseToOwn.text.submitFiles" />
															</p>

															{!!error ? (
																<div className="form__message form__message--error">{error}</div>
															) : null}

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="emiratesId">
																			<Localized code="page.insurance.label.emiratesIdFront" />
																		</label>
																		<FileUpload capture accept="image/*" name="emiratesId" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.emiratesId !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentImage" />
																			</label>
																			{!!uploads.emiratesId.uri ? (
																				<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.emiratesId.uri}`} className="lease-to-own__image" />
																			) : null}
																			{!!uploads.emiratesId.progress ? (
																				<ProgressBar value={uploads.emiratesId.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="emiratesIdBack">
																			<Localized code="page.insurance.label.emiratesIdBack" />
																		</label>
																		<FileUpload capture accept="image/*" name="emiratesIdBack" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.emiratesIdBack !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentImage" />
																			</label>
																			{!!uploads.emiratesIdBack.uri ? (
																				<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.emiratesIdBack.uri}`} className="lease-to-own__image" />
																			) : null}
																			{!!uploads.emiratesIdBack.progress ? (
																				<ProgressBar value={uploads.emiratesIdBack.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="drivingLicense">
																			<Localized code="page.insurance.label.drivingLicenseFront" />
																		</label>
																		<FileUpload capture accept="image/*" name="drivingLicense" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.drivingLicense !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentImage" />
																			</label>
																			{!!uploads.drivingLicense.uri ? (
																				<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.drivingLicense.uri}`} className="lease-to-own__image" />
																			) : null}
																			{!!uploads.drivingLicense.progress ? (
																				<ProgressBar value={uploads.drivingLicense.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="drivingLicenseBack">
																			<Localized code="page.insurance.label.drivingLicenseBack" />
																		</label>
																		<FileUpload capture accept="image/*" name="drivingLicenseBack" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.drivingLicenseBack !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentImage" />
																			</label>
																			{!!uploads.drivingLicenseBack.uri ? (
																				<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.drivingLicenseBack.uri}`} className="lease-to-own__image" />
																			) : null}
																			{!!uploads.drivingLicenseBack.progress ? (
																				<ProgressBar value={uploads.drivingLicenseBack.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="salaryCertificate">
																			<Localized code="page.insurance.label.salaryCertificate" />
																		</label>
																		<FileUpload capture accept="image/*" name="salaryCertificate" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.salaryCertificate !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentImage" />
																			</label>
																			{!!uploads.salaryCertificate.uri ? (
																				<img alt="Uploaded" src={`${Config.UPLOADS_ENDPOINT}${uploads.salaryCertificate.uri}`} className="lease-to-own__image" />
																			) : null}
																			{!!uploads.salaryCertificate.progress ? (
																				<ProgressBar value={uploads.salaryCertificate.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__row">
																<div className="form__column">
																	<div className="form__group">
																		<label className="form__label" htmlFor="bankStatement">
																			<Localized code="page.insurance.label.bankStatement" />
																		</label>
																		<FileUpload accept="application/pdf" name="bankStatement" placeholder={translateMessage('generic.text.selectFile')} onChange={this.handleFileChange} />
																	</div>
																</div>

																<div className="form__column">
																	{typeof uploads.bankStatement !== typeof undefined ? (
																		<div className="form__group">
																			<label className="form__label">
																				<Localized code="page.insurance.label.currentPDF" />
																			</label>
																			{!!uploads.bankStatement.uri ? (
																				<div className="lease-to-own__pdf">
																					<FontAwesomeIcon icon={faFilePdf} className="lease-to-own__pdf-icon" />
																					<span className="lease-to-own__pdf-name">{uploads.bankStatement.originalFileName}</span>
																				</div>
																			) : null}
																			{!!uploads.bankStatement.progress ? (
																				<ProgressBar value={uploads.bankStatement.progress} />
																			) : null}
																		</div>
																	) : null}
																</div>
															</div>

															<div className="form__buttons">
																<button disabled={submitDisabled || userLoading} className="form__button form__button--gold form__button--has-loading" type="submit" onClick={this.handleLeaseToOwnSubmitClick}>
																	{loading ? (
																		<ReactLoading className="form__button-loading" type="spin" color="#FFF" height="20px" width="20px" />
																	) : null}
																	<Localized code="page.leaseToOwn.button.submit" />
																</button>
															</div>
														</div>
													)}
												</div>
											) : (
												<Fragment>
													<p className="section__text section__text--lead section__text--center">
														<Localized code="page.leaseToOwn.text.accountUpgradeRequired" />
													</p>

													{!noUpgrade && (
														<p className="form__text form__text--center">
															<Link to="/account/upgrade" className="button button--gold">
																<Localized code="page.leaseToOwn.button.upgradeAccount" />
															</Link>
														</p>
													)}
												</Fragment>
											)
										) : (
											<Fragment>
												<p className="section__text section__text--lead section__text--center">
													<Localized code="page.leaseToOwn.text.accountRequired" />
												</p>

												<div className="section__columns">
													<div className="section__column section__column--center">
														<button className="button button--blue" onClick={this.handleSignIn}>
															<Localized code="page.signIn.button.signIn" />
														</button>
													</div>

													<div className="section__column section__column--center">
														<button className="button button--blue" onClick={this.handleSignUp}>
															<Localized code="page.signIn.link.signUp" />
														</button>
													</div>
												</div>
											</Fragment>
										)}
									</div>
								</div>
							</section>
						</Fragment>
					) : (
						<HTTP404 />
					)
				) : null
			);
		}

	}
)));

export default Car;
